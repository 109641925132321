import React, { useState } from "react";

const HomeScreen = () => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleNotifySubmit = (e) => {
    e.preventDefault();
    console.log(`Notification email submitted: ${email}`);
    setSubmitted(true);
    setEmail(""); // Clear input
  };

  const animationKeyframes = `
    @keyframes fadeInUp {
      0% {
        opacity: 0;
        transform: translateY(20px);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }

    @keyframes glow {
      0%, 100% {
        box-shadow: 0 0 10px rgba(255, 255, 255, 0.6), 0 0 20px rgba(255, 255, 255, 0.4);
      }
      50% {
        box-shadow: 0 0 20px rgba(255, 255, 255, 0.8), 0 0 40px rgba(255, 255, 255, 0.6);
      }
    }

    @keyframes buttonHoverPulse {
      0%, 100% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.05);
      }
    }
  `;

  const containerStyle = {
    height: "100vh",
    background: "linear-gradient(135deg, #000000, #ffffff)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    color: "#fff",
    position: "relative",
    overflow: "hidden",
  };

  const gradientOverlay = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "radial-gradient(circle, rgba(255,255,255,0.2), rgba(0,0,0,0.8))",
    zIndex: -1,
  };

  const logoStyle = {
    animation: "fadeInUp 1.5s ease-out forwards",
    marginBottom: "2rem",
    filter: "drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.7))",
  };

  const textStyle = {
    fontSize: "4rem",
    fontWeight: "bold",
    margin: "1rem 0",
    textShadow: "2px 2px 10px rgba(0, 0, 0, 0.7)",
    animation: "fadeInUp 2s ease-out forwards",
  };

  const subTextStyle = {
    fontSize: "1.5rem",
    marginBottom: "3rem",
    color: "#e0e0e0",
    animation: "fadeInUp 2.5s ease-out forwards",
  };

  const formStyle = {
    marginTop: "2rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const inputStyle = {
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #fff",
    marginBottom: "10px",
    width: "300px",
    textAlign: "center",
  };

  const buttonStyle = {
    padding: "15px 30px",
    borderRadius: "25px",
    border: "none",
    background: "linear-gradient(90deg, #ffffff, #000000)",
    color: "#fff",
    fontSize: "1.2rem",
    fontWeight: "bold",
    cursor: "pointer",
    transition: "transform 0.3s ease, background 0.3s ease",
    animation: "buttonHoverPulse 2s infinite ease-in-out",
  };

  const buttonHoverFocus = `
    button:hover {
      background: linear-gradient(90deg, #000000, #ffffff);
      color: #000;
      transform: scale(1.05);
    }
  `;

  const successMessageStyle = {
    fontSize: "1.8rem",
    color: "#00ff00",
    marginTop: "2rem",
    animation: "fadeInUp 2s ease-out forwards",
  };

  return (
    <div>
      <style>
        {animationKeyframes}
        {buttonHoverFocus}
      </style>
      <div style={containerStyle}>
        <div style={gradientOverlay}></div>
        <img
          src="/images/logo.png"
          alt="Logo"
          width="180"
          style={logoStyle}
        />
        <div style={textStyle}>Coming Soon</div>
        <div style={subTextStyle}>
          Something exciting is on the way. Be the first to know!
        </div>

        {/* Added Text Before Input */}
        <p style={{ fontSize: "1.2rem", color: "#fff", marginBottom: "10px" }}>
          Stay updated by entering your email below:
        </p>

        {!submitted ? (
          <form style={formStyle} onSubmit={handleNotifySubmit}>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={handleEmailChange}
              style={inputStyle}
              required
            />
            <button type="submit" style={buttonStyle}>
              Notify Me
            </button>
          </form>
        ) : (
          <div style={successMessageStyle}>
            Thank you! We’ll notify you when we launch.
          </div>
        )}
      </div>

      {/* Simple Footer */}
      <footer style={{ textAlign: "center", padding: "1rem", backgroundColor: "#000", color: "#fff" }}>
        <p>&copy; 2024 Wamae Arts. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default HomeScreen;















// import React, { useRef } from "react";
// import Header from "./../components/Header";
// import ShopSection from "./../components/homeComponents/ShopSection";
// import PortraitsSection from "./../components/homeComponents/PortraitsSection";
// import CalltoActionSection from "./../components/homeComponents/CalltoActionSection";
// import ContactInfo from "./../components/homeComponents/ContactInfo";
// import Footer from "./../components/Footer";

// const HomeScreen = ({ match }) => {
//   window.scrollTo(0, 0);
//   const keyword = match.params.keyword;
//   const pagenumber = match.params.pagenumber;

//   const shopSectionRef = useRef(null);

//   const handleScrollToShop = () => {
//     if (shopSectionRef.current) {
//       shopSectionRef.current.scrollIntoView({
//         behavior: 'smooth',
//         block: 'start',
//       });
//     }
//   };

//   const animationKeyframes = `
//     @keyframes zoomIn {
//       0% {
//         transform: scale(1);
//       }
//       50% {
//         transform: scale(1.1);
//       }
//       100% {
//         transform: scale(1);
//       }
//     }
//   `;

//   const containerStyle = {
//     position: 'relative',
//     height: '605px',
//     overflow: 'hidden',
//   };

//   const backgroundStyle = {
//     content: '""',
//     position: 'absolute',
//     top: 0,
//     left: 0,
//     width: '100%',
//     height: '100%',
//     backgroundImage: 'url("/images/header_img.png")',
//     backgroundSize: 'cover',
//     backgroundPosition: 'center',
//     animation: 'zoomIn 10s ease-in-out infinite',
//     zIndex: -1,
//   };

//   const mobileTextStyle = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     fontSize: '12px',
//     color: '#fff',
//     textAlign: 'center',
//     fontWeight: 'bold',
//     display: 'none',
//   };

//   return (
//     <div>
//       <style>
//         {animationKeyframes}
        
//         {`@media (max-width: 768px) {
//           .mobile-center-text {
//             display: block !important; /* Show text on small screens */
//           }
//         }`}
//       </style>
//       {!keyword && (
//         <div style={containerStyle}>
//           <div style={backgroundStyle}></div>
//           <Header className="header" />
//           <div className="mobile-center-text" style={mobileTextStyle}>
//             Unveiling Beauty Through Art <br/>
//           </div>
//           <div className="button-container">
//             <button 
//               type="button" 
//               className="shop-my-art-button rounded drop-shadow"
//               onClick={handleScrollToShop}
//             >
//               SHOP NOW
//             </button>
//           </div>
//         </div>
//       )}
//       {keyword && <Header className="header" />}
//       <ShopSection 
//         keyword={keyword} 
//         pagenumber={pagenumber} 
//         ref={shopSectionRef}
//       />
//       {!keyword && <PortraitsSection />}
//       <CalltoActionSection />
//       <hr className="mt-4 border-gray-300" />
//       <ContactInfo />
//       <Footer />
//     </div>
//   );
// };

// export default HomeScreen;
