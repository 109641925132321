import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listProduct } from "../Redux/Actions/ProductActions";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Product from "../components/Product";
import Loader from "./../components/LoadingError/Loading";
import Message from "./../components/LoadingError/Error";

const PrintsScreen = () => {
  const dispatch = useDispatch();

  const productList = useSelector((state) => state.productList);
  const { loading, error, products = [] } = productList;

  useEffect(() => {
    dispatch(listProduct("prints"));
  }, [dispatch]);

  return (
    <div>
      <Header />
      <div className="prints-intro">
        <h1 className="prints-title">Welcome to My Gallery!</h1>
        <p className="prints-description">
          Discover art that tells unique stories through each portrait. From familiar faces to iconic personalities, explore meticulously crafted artworks. Whether admiring, seeking inspiration, or finding a perfect piece, enjoy your journey through my collection.
        </p>
        <div className="prints-divider"></div>
      </div>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <div className="gallery">
          {products.length > 0 ? (
            products.map((product) => (
              <Product key={product._id} product={product} />
            ))
          ) : (
            <p>No products available</p>
          )}
        </div>
      )}
      <Footer />
    </div>
  );
};

export default PrintsScreen;
