import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createCommission } from "../../Redux/Actions/CommissionActions";

const CalltoActionSection = () => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    description: "",
    dimensions: "",
    additionalNotes: "",
  });

  const [showNotification, setShowNotification] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createCommission(formData));
    setFormData({
      fullName: "",
      email: "",
      description: "",
      dimensions: "",
      additionalNotes: "",
    });
    
    setShowNotification(true);
    
    setTimeout(() => setShowNotification(false), 3000);
  };

  return (
    <div className="commission-section">
      <h1>COMMISSION YOUR DREAM ART</h1>
      <p>
        Please share the details of your project so I can understand it better.
        Once I have your request, I'll get in touch to discuss the specifics and
        provide a quote.
      </p>

      {showNotification && (
        <div className="notification">
          Your commission request has been submitted successfully!
        </div>
      )}

      <div className="commission-form">
        <h2>Commission Request Form</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="fullName">Full Name</label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              placeholder="Your full name."
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="email"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="description">Description of the Commission</label>
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              placeholder="Please describe your idea in detail, including specific elements, the style you want, and any references you have."
              required
            ></textarea>
          </div>

          <div className="form-group">
            <label htmlFor="dimensions">Dimensions/Size</label>
            <input
              type="text"
              id="dimensions"
              name="dimensions"
              value={formData.dimensions}
              onChange={handleChange}
              placeholder="e.g. A1, A2, A3, A4, A5"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="additionalNotes">Additional Notes</label>
            <textarea
              id="additionalNotes"
              name="additionalNotes"
              value={formData.additionalNotes}
              onChange={handleChange}
            ></textarea>
          </div>

          <button type="submit" className="submit-btn">
            Submit
          </button>
        </form>
      </div>

     \
      <style>
        {`
          .notification {
            position: fixed;
            top: 20px;
            right: 20px;
            background-color: #4CAF50;
            color: white;
            padding: 15px;
            border-radius: 5px;
            z-index: 1000;
            animation: fadeIn 0.5s, fadeOut 0.5s 2.5s;
          }

          @keyframes fadeIn {
            from {opacity: 0;}
            to {opacity: 1;}
          }

          @keyframes fadeOut {
            from {opacity: 1;}
            to {opacity: 0;}
          }
        `}
      </style>
    </div>
  );
};

export default CalltoActionSection;
