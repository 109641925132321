import React from "react";
import { Link } from "react-router-dom";

const handleWhatsAppClick = () => {
  window.location.href = 'https://wa.me/254746003525';
};

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <img src="/images/logo.png" alt="WK Logo" />
        </div>
        <div className="footer-section">
          <h3>Contacts</h3>
          <p>wamaearts@gmail.com</p>
          <p>+254 746 003 525</p>
        </div>
        <div className="footer-section">
          <h3>Menu</h3>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/prints">Prints</Link></li>
            <li><Link to="/commissions">Commissions</Link></li>
            <li><Link to="/about">About</Link></li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>Company</h3>
          <ul>
            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            <li><Link to="/terms-conditions">Terms & Conditions</Link></li>
            <li><Link to="/licence">Licence</Link></li>
          </ul>
        </div>
      </div>
      <div className="footer-social">
        <h3>FOLLOW ME</h3>
        <div className="social-icons">
        <a href="https://www.facebook.com/kelvin.wamae.988" target="_blank" 
              rel="noopener noreferrer" className="social-icon facebook"><i className="fab fa-facebook-f"></i></a>

<a href="https://instagram.com/wamae_k" target="_blank" 
              rel="noopener noreferrer" className="social-icon instagram"><i className="fab fa-instagram"></i></a>

<a href="https://twitter.com/Wamae4art" target="_blank" 
              rel="noopener noreferrer" className="social-icon twitter"><i className="fab fa-twitter"></i></a>

<a href="https://tiktok.com/@wamae_k" target="_blank" 
              rel="noopener noreferrer" className="social-icon tiktok"><i className="fab fa-tiktok"></i></a>

<a href="https://www.youtube.com/@wamae_arts" target="_blank" 
              rel="noopener noreferrer" className="social-icon youtube"><i className="fab fa-youtube"></i></a>

<button className="social-icon whatsapp" onClick={handleWhatsAppClick}>
  <i className="fab fa-whatsapp"></i>
</button>
        </div>
      </div>
      <div className="footer-copyright">
        <p>Copyright © 2024 Wamae Arts All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
