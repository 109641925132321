import React from 'react';
import { Link } from 'react-router-dom';

const Product = ({ product }) => {
  console.log("Rendering product:", product); 

  return (
    <div className="product-card">
      <Link to={`/products/${product._id}`} className="product-link">
        <img 
          src={product.image || '/images/default-product.jpg'} 
          alt={product.name} 
          className="product-image" 
        />
        <h3 className="product-name">{product.name}</h3>
        <p className="product-price">Ksh {product.price}</p>
      </Link>
    </div>
  );
};

export default Product;
