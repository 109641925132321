import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../Redux/Actions/userActions";

const Header = () => {
  const [keyword, setKeyword] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dispatch = useDispatch();
  let history = useHistory();
  const location = useLocation(); 

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const handleWhatsAppClick = () => {
    const phoneNumber = "+254746003525"; 
    const message = "Hello, I would like to know more about your art!";
    window.open(`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`, "_blank");
  };

  const logoutHandler = () => {
    dispatch(logout());
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (keyword.trim()) {
      history.push(`/search/${keyword}`);
    } else {
      history.push("/");
    }
  };

  const logoSrc =
    location.pathname === "/" ? "/images/logo.png" : "/images/logo3.png"; 

  // Conditional class for nav links and cart icon
  const navLinkClass = location.pathname === "/" ? "nav-link-white" : "nav-link-black";
  const buttonLinkClass = location.pathname === "/" ? "btn-link-white" : "btn-link-black";
  const cartIconClass = location.pathname === "/" ? "cart-icon-white" : "cart-icon-black";
  const menuButtonColor = location.pathname === "/" ? "menu-button-white" : "menu-button-black";

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div>
      {/* MOBILE HEADER */}
      <div className="mobile-header flex">
        <div className="container">
          <div className="row">
            <div className="col-6 d-flex align-items-center">
              <Link className="navbar-brand" to="/">
                <img alt="logo" src={logoSrc} />
              </Link>
            </div>
            <div className="col-6 d-flex align-items-center justify-content-end">
              <Link to="/cart" className={`cart-mobile-icon ${cartIconClass}`}>
                <i className="fas fa-shopping-bag"></i>
                <span className="mobile-badge">{cartItems.length}</span>
              </Link>
              <button className={`menu-button ${menuButtonColor}`} onClick={toggleMenu}>
                <i className="fas fa-bars"></i>
              </button>
            </div>
          </div>
        </div>
        {/* Mobile Menu */}
        <div className={`mobile-menu ${isMenuOpen ? "open" : ""}`}>
          <div className="mobile-menu-overlay" onClick={toggleMenu}></div>
          <div className="mobile-menu-content">
            <button className="close-button" onClick={toggleMenu}>
              <i className="fas fa-times"></i>
            </button>
            <nav>
              <ul className="nav-list">
                <li className="nav-item">
                  <Link className={`mobile-nav-link ${navLinkClass}`} to="/" onClick={toggleMenu}>
                    HOME
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={`mobile-nav-link ${navLinkClass}`} to="/prints" onClick={toggleMenu}>
                    PRINTS
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={`mobile-nav-link ${navLinkClass}`} to="/commissions" onClick={toggleMenu}>
                    COMMISSIONS
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={`mobile-nav-link ${navLinkClass}`} to="/about" onClick={toggleMenu}>
                    ABOUT
                  </Link>
                </li>
                {userInfo ? (
                  <>
                    <li className="nav-item">
                      <Link className="mobile-nav-link" to="/profile" onClick={toggleMenu}>
                        MY ACCOUNT
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="mobile-nav-link" to="#" onClick={() => { logoutHandler(); toggleMenu(); }}>
                        LOGOUT
                      </Link>
                    </li>
                  </>
                ) : (
                  <>
                    <li className="nav-item">
                      <Link className={`mobile-nav-link ${buttonLinkClass}`} to="/register" onClick={toggleMenu}>
                        REGISTER
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className={`mobile-nav-link ${buttonLinkClass}`} to="/login" onClick={toggleMenu}>
                        LOGIN
                      </Link>
                    </li>
                    <div className="mobile-social-icons">
          <a
            href="https://www.facebook.com/kelvin.wamae.988"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon facebook"
          >
            <i className="fab fa-facebook-f"></i>
          </a>

          <a
            href="https://instagram.com/wamae_k"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon instagram"
          >
            <i className="fab fa-instagram"></i>
          </a>

          <a
            href="https://twitter.com/Wamae4art"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon twitter"
          >
            <i className="fab fa-twitter"></i>
          </a>

          <a
            href="https://tiktok.com/@wamae_k"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon tiktok"
          >
            <i className="fab fa-tiktok"></i>
          </a>

          <a
            href="https://www.youtube.com/@wamae_arts"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon youtube"
          >
            <i className="fab fa-youtube"></i>
          </a>

          <button className="social-icon whatsapp" onClick={handleWhatsAppClick}>
            <i className="fab fa-whatsapp"></i>
          </button>
        </div>
                  </>
                )}
              </ul>
            </nav>
          </div>
        </div>
      </div>

      {/* PC HEADER */}
      <div className="pc-header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-2">
              <Link className="navbar-brand" to="/">
                <img alt="logo" src={logoSrc} />
              </Link>
            </div>
            <div className="col-md-5">
              <nav>
                <ul className="nav">
                  <li className="nav-item">
                    <Link className={`nav-link ${navLinkClass}`} to="/">HOME</Link>
                  </li>
                  <li className="nav-item">
                    <Link className={`nav-link ${navLinkClass}`} to="/prints">PRINTS</Link>
                  </li>
                  <li className="nav-item">
                    <Link className={`nav-link ${navLinkClass}`} to="/commissions">COMMISSIONS</Link>
                  </li>
                  <li className="nav-item">
                    <Link className={`nav-link ${navLinkClass}`} to="/about">ABOUT</Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col-md-5">
              <div className="d-flex justify-content-end align-items-center">
                <form onSubmit={submitHandler} className="input-group mr-3">
                  <input
                    type="search"
                    className="form-control rounded search"
                    placeholder="Search"
                    onChange={(e) => setKeyword(e.target.value)}
                  />
                  <button type="submit" className="search-button">
                    Search
                  </button>
                </form>
                {userInfo ? (
                  <div className="btn-group">
                    <button
                      type="button"
                      className="name-button dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      style={{ color: '#000000' }}
                    >
                      Hi, {userInfo.name}
                    </button>
                    <div className="dropdown-menu">
                      <Link className="dropdown-item" to="/profile">
                        My Account
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="#"
                        onClick={logoutHandler}
                      >
                        Logout
                      </Link>
                    </div>
                  </div>
                ) : (
                  <>
                    <Link to="/register" className={`btn ${buttonLinkClass}`}>Register</Link>
                    <Link to="/login" className={`btn ${buttonLinkClass}`}>Login</Link>
                  </>
                )}
                <Link to="/cart" className={`cart-icon ${cartIconClass}`}>
                  <i className="fas fa-shopping-bag"></i>
                  <span className="badge">{cartItems.length}</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
  