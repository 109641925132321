import {
    CLASS_SIGNUP_REQUEST,
    CLASS_SIGNUP_SUCCESS,
    CLASS_SIGNUP_FAIL
  } from '../Constants/classConstants';
  
  export const classSignupReducer = (state = {}, action) => {
    switch (action.type) {
      case CLASS_SIGNUP_REQUEST:
        return { loading: true };
      case CLASS_SIGNUP_SUCCESS:
        return { loading: false, success: true, response: action.payload };
      case CLASS_SIGNUP_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  