import axios from "axios";
import {
  COMMISSION_CREATE_REQUEST,
  COMMISSION_CREATE_SUCCESS,
  COMMISSION_CREATE_FAIL,
} from "../Constants/CommissionConstants";

// Base API URL
// const apiUrl = process.env.REACT_APP_API_URL; // use '${apiUrl}' in before api endpoints

export const createCommission = (commissionData) => async (dispatch) => {
  try {
    dispatch({ type: COMMISSION_CREATE_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(`/api/commissions`, commissionData, config);

    dispatch({ type: COMMISSION_CREATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: COMMISSION_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
