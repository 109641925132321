import React, { useEffect, useState } from "react";
import Header from "./../components/Header";
// import Rating from "../components/homeComponents/Rating";
// import { Link } from "react-router-dom";
import Message from "./../components/LoadingError/Error";
import { useDispatch, useSelector } from "react-redux";
import {
  // createProductReview,
  listProductDetails,
} from "../Redux/Actions/ProductActions";
import Loading from "../components/LoadingError/Loading";
import { PRODUCT_CREATE_REVIEW_RESET } from "../Redux/Constants/ProductConstants";
// import moment from "moment";

const SingleProduct = ({ history, match }) => {
  const [qty] = useState(1); //setQty
  // const [setRating, rating] = useState(0); 
  // const [comment, setComment] = useState(""); 

  const productId = match.params.id;
  const dispatch = useDispatch();

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;
  // const userLogin = useSelector((state) => state.userLogin);
  // const { userInfo } = userLogin;
  const productReviewCreate = useSelector((state) => state.productReviewCreate);
  const {
    // loading: loadingCreateReview,
    // error: errorCreateReview,
    success: successCreateReview,
  } = productReviewCreate;

  useEffect(() => {
    if (successCreateReview) {
      alert("Review Submitted");
      // setRating(0);
      // setComment("");
      dispatch({ type: PRODUCT_CREATE_REVIEW_RESET });
    }
    dispatch(listProductDetails(productId));
  }, [dispatch, productId, successCreateReview]);

  const AddToCartHandle = (e) => {
    e.preventDefault();
    history.push(`/cart/${productId}?qty=${qty}`);
  };
  
  // const submitHandler = (e) => {
  //   e.preventDefault();
  //   dispatch(
  //     createProductReview(productId, {
  //       rating,
  //       comment,
  //     })
  //   );
  // };

  return (
    <>
      <Header className="product-header"/>
      <div className="full-width-bg">
      <div className="container single-product">
        {loading ? (
          <Loading />
        ) : error ? (
          <Message variant="alert-danger">{error}</Message>
        ) : (
          <div className="row">
            <div className="col-md-6">
              <div className="single-image">
                <img src={product?.image} alt={product?.name} />
              </div>
            </div>
            <div className="col-md-6">
              <h2 className="product-name">{product?.name?.toUpperCase()}</h2>
              <h3 className="product-price">Ksh {product?.price}</h3>
              <div className="product-options">
                <button className="option-btn">Framed Print</button>
                <button className="option-btn">Original Drawing</button>
              </div>
              <div className="product-sizes">
                <button className="size-btn">A1</button>
                <button className="size-btn">A2</button>
                <button className="size-btn">A3</button>
                <button className="size-btn">A4</button>
              </div>
              <button onClick={AddToCartHandle} className="add-to-cart-btn">
                ADD TO CART
              </button>
              <div className="product-description">
                <p>"{product?.description}"</p>
              </div>
            </div>
          </div>
        )}
      </div>
      </div>
    </>
  );
};

export default SingleProduct;
