import React, { useState } from "react";
import Header from "../components/Header";
import CalltoActionSection from "../components/homeComponents/CalltoActionSection";
import Footer from "../components/Footer";

const AboutScreen = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [classType, setClassType] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // const apiUrl = process.env.REACT_APP_API_URL; // use '${apiUrl}' in before api endpoints

  const submitHandler = async (e) => {
    e.preventDefault();
    
    if (!name || !email || !phone || !classType) {
      setError('Please fill in all fields.');
      return;
    }
  
    try {
      const response = await fetch(`/api/classes/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, phone, classType }),
      });
  
      if (!response.ok) {
        throw new Error('Something went wrong.');
      }
  
      setSuccess('Successfully signed up for the class!');
      setName('');
      setEmail('');
      setPhone('');
      setClassType('');
      setError('');
    } catch (error) {
      setError(error.message);
      setSuccess('');
    }
  };

  return (
    <>
      <Header />
      <div className="about-container">
        <div className="about-image">
          <img src="/images/about.png" alt="About Wamae" />
        </div>
        <div className="about-content">
          <div className="about-text">
            <h2>About Me<br/><br/></h2>
            <h3>Welcome to My Art Gallery!<br/></h3>
            <p>
              Hello, I'm Wamae, a passionate portrait artist based in the heart of Juja. My art gallery is a place where I bring faces to life on canvas, capturing the essence and spirit of each individual. With a keen eye for detail and a dedication to my craft, my portraits are more than just representations; they are a window into the soul of each subject.
            </p>
            <h3>My Story</h3>
            <p>
              My journey into the world of art began at a young age. Growing up in Juja, I was always fascinated by the ability of a simple pencil to create intricate and expressive portraits. My talent quickly became evident as I started sketching friends, family, and local personalities. Encouraged by the positive feedback and the joy my art brought to others, I decided to pursue my passion full-time.
            </p>
          </div>
        </div>
      </div>
      <hr className="mt-4 border-gray-300" />
      <div className="classes-section">
        <h2>Upcoming Classes</h2>
        <p>
        Join me for pencil art classes suitable for all skill levels. Whether you’re a beginner or an experienced artist, these sessions will help you refine your skills and explore new techniques.
        </p>
        <ul>
          <li><strong>Introduction to Portrait Drawing</strong> - Master the fundamentals of pencil portrait drawing, including proportions, shading techniques, and capturing lifelike expressions.</li>
          <li><strong>Advanced Pencil Techniques</strong> - Explore advanced methods for creating detailed textures and subtle tonal variations in your pencil drawings.</li>
          <li><strong>Creative Sketching Workshop</strong> - Ignite your creativity with engaging and imaginative sketching exercises designed to push your artistic boundaries.</li>
        </ul>
        <form onSubmit={submitHandler} className="signup-form">
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone:</label>
            <input
              type="text"
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="classType">Select a Class</label>
            <select
              id="classType"
              value={classType}
              onChange={(e) => setClassType(e.target.value)}
            >
              <option value="">Select a class</option>
              <option value="Introduction to Portrait Drawing">Introduction to Portrait Drawing</option>
              <option value="Advanced Pencil Techniques">Advanced Pencil Techniques</option>
              <option value="Creative Sketching Workshop">Creative Sketching Workshop</option>
            </select>
          </div>
          <button type="submit" className="signup-button">Sign Up</button>
          {error && <p>{error}</p>}
          {success && <p>{success}</p>}
        </form>
      </div>
      <hr className="mt-4 border-gray-300" />
      <CalltoActionSection />
      <Footer />
    </>
  );
};

export default AboutScreen;
