import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listProduct } from "../Redux/Actions/ProductActions";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Product from "../components/Product";
import Loader from "./../components/LoadingError/Loading";
import Message from "./../components/LoadingError/Error";

const CommissionsScreen = () => {
  const dispatch = useDispatch();

  const productList = useSelector((state) => state.productList);
  const { loading, error, products = [] } = productList;

  useEffect(() => {
    dispatch(listProduct("commissions"));
  }, [dispatch]);

  return (
    <div>
      <Header />
      <div className="commissions-intro">
        <h1 className="commissions-title">Bring Your Vision to Life!</h1>
        <p className="commissions-description">
          Are you looking for a one-of-a-kind piece that captures your unique story or celebrates a special moment? 
          Let's work together to create a stunning artwork tailored just for you. Whether it's a portrait, a creative concept, or a meaningful gift, I'm here to bring your imagination to life on canvas.
        </p>
        <button className="commissions-button">Start Your Commission</button>
        <div className="commissions-divider"></div>
      </div>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <div className="gallery">
          {products.map((product) => (
            <Product key={product._id} product={product} />
          ))}
        </div>
      )}
      <Footer />
    </div>
  );
};

export default CommissionsScreen;
